
function refresh() {
  Promise.all([
    fetch("/api/balance").then((r) => r.json()),
    fetch("/api/qrcode").then((r) => r.json()),
  ]).then(([balance, code]) => {
    document.querySelector("#balance").innerHTML =
      `余额: ${balance.data.data.cardInfoList[0].cardBaseInfo.cardBalance}`;
    const codeWriter = new ZXing.BrowserQRCodeSvgWriter();
    document.querySelector('#qrcode').innerHTML = '';
    console.log("ZXing code writer initialized");
    let svgElement;
    svgElement = codeWriter.writeToDom(
      "#qrcode",
      `${code.data.data.paymentCode}\$${code.data.data.dynamicPwd}`,
      300,
      300
    );
    JsBarcode("#barcode", `${code.data.data.paymentCode}\$${code.data.data.dynamicPwd}`);
  }).catch((e)=>{
    alert('获取二维码失败了，联系我刷新一下，秒好')
    console.error(e)
  });
}

document.addEventListener("click", refresh);
setTimeout(() => {
  refresh();
}, 1000 * 30);
window.addEventListener("load", () => {
  refresh();
});
